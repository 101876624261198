export const EN_STRING_OVERRIDES = {
  MANULIFE: 'Manulife',
  MANULIFE_DARK: 'Manulife Dark',
  MANULIFE_LIGHT: 'Manulife Light',
  CONFIGURABLE_JOURNEY_HEADER_DESCRIPTION:
    'Balance represents total points earned within the Manulife app. For more details on total Aeroplan points or what these points mean, visit [insert external link out TBD].',
  YOUVE_GOT_THIS: 'Welcome to your Journey',
  YOUVE_GOT_THIS_NAME: 'Welcome to your Journey, {firstName}!',
  LOG_OUT: 'Log Out',
  JOURNEY: 'Journey',
  HOME: 'Home',
};
